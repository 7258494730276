import React from 'react';
import Navigation from '../NavigationBar/NavigationBar';
import HomeSlider from '../Slider/HomeSlider';
import Animate from '../Animation/Animate';
import JobDescription from '../Button/JobDescrip';
import HomePageContent from '../HomePageContent';
import Contactus from '../../Contactus/Contactus';
import Marquee from '../../../Marquee/Marquee';
import OurServices from '../../../Marquee/OurServices';
// import Ourprocess from '../../Services/Ourprocess';



const Home = () => {
  return (
    <div>   
         
      <Navigation />
      <HomeSlider />
      <Animate />
      <JobDescription />
      <HomePageContent />
      <Marquee/>
      <OurServices />
      {/* <Ourprocess/> */}
      <Contactus /> 
      
    </div>
  );
};

export default Home;