const NonITJobDetails = [
    {
      designation:"",
      company: "MyInception Tech",
      salaryRange: "8000 - 12000 (Month)",
      experience: "2-7 Years",
      location: "Work in Office - T.Nagar, Chennai",
      vacancy: 1,
      postedOn: new Date("2024-03-"),
      jobDescription: [
        {
          title:
            "",
          industry: "Non-IT",
          responsibilities: [
            ""
          ],
          requirements: [
            ""
          ],
        },
      ],
    },
    {
      designation: "",
      company: "MyInception Tech",
      salaryRange: "Rs.40,000 ( Based on Experience)",
      experience: "",
      location: "Work in Office - T.Nagar, Chennai",
      vacancy: 1,
      postedOn: new Date("2024-03-"),
      jobDescription: [
        {
          title: "",
          industry: "Non-IT",
          responsibilities: [
            ""
          ],
          requirements: [""],
        },
      ],
    },
  
    {
      designation: "",
      company: "MyInception Tech",
      salaryRange: "Not Disclosed",
      experience: "",
      location: "T.Nagar, Chennai",
      vacancy: 1,
      postedOn: new Date("2024-03-15"),
      jobDescription: [
        {
          title: "",
          industry:"Non-IT",
        
          responsibilities: [
            "",
            
          ],
          requirements: [""],
        },
      ],
    },{
      designation: "",
      company: "MyInception Tech",
      salaryRange: "Not Disclosed",
      experience:"",
      location: "T.Nagar, Chennai",
      vacancy: 1,
      postedOn: new Date("2024-03-"),
      jobDescription: [
        {
          title: "",
          industry:"Non-IT",
        
          responsibilities: [
            ""
          ],
          requirements: [""],
    }]},
  ];
  
  export default NonITJobDetails;
  