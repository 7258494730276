const JobDetails = [
  {
    designation: "Academic Counsellor",
    company: "MyInception Tech",
    salaryRange: "8000 - 12000 (Month)",
    experience: "0-3 Years",
    location: " T.Nagar, Chennai",
    vacancy: 1,
    postedOn: new Date("2024-09-13"),
    jobDescription: [
      {
        title: "Academic Counsellor",
        industry: "NonIT",
        responsibilities: [
          "Should have rich experience in inside sales and driving admissions in software education space.",
          "Should convert student inquiries and leads into admissions.",
          "Should have hands-on experience in lead generation.",
          "Should have knowledge in sourcing candidates and building up a database.",
          "Actively track and follow up on potential leads.",
          "Should have proven records in target achievement.",
          "Should have good communication skills.",
        ],
        requirements: ["Any degree/BE (CSE,IT,ECE,EEE)(Highly prefered)."],
      },
    ],
  },
  {
    designation: "Hr Recruiter",
    company: "MyInception Tech",
    salaryRange: "Rs.40,000 ( Based on Experience)",
    experience: " 3-4 Years",
    location: "T.Nagar, chennai. ",
    vacancy: 1,
    postedOn: new Date("2024-09-13"),
    jobDescription: [
      {
        title: "Hr Recruiter",
        industry: "NonIT",
        responsibilities: [
          "Managing the end-to-end recruitment cycle",
          "Create & post job ads on various job portals & social media",
          "Assist in the mass hiring process by sourcing, screening, shortlisting, & interviewing candidates",
          "Assess and anticipate human resources-related needs",
          "Develop and nurture partnerships through human resources to bridge the divide between management and employees",
          "Ensure compliance with company policies and procedures during all stages of recruitment",
          "Collaborate with hiring managers to understand their requirements and provide guidance on interview processes",
          "Assist with Documentation and Onboarding processes",
          "Should have excellent communication",
          "Should have proven records in recruitment",
        ],
        requirements: [
          "Bachelor's degree or Master's Degree in  related field",
        ],
      },
    ],
  },

  {
    designation: "Senior Accountant",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "0-3 Years",
    location: "Ekkatuthangal",
    vacancy: 1,
    postedOn: new Date("2024-09-13"),
    jobDescription: [
      {
        title: "Senior Accountant",
        industry: "NonIT",

        responsibilities: [
          "Financial Reporting: Prepare and analyze monthly, quarterly, and annual financial statements",
          "Budget Management: Develop and manage annual budgets, forecasts, and financial plans",
          "Fundraising: Lead efforts to raise funds for the company through various financial instruments, including bank guarantees, NFBCs, and loans",
          "Loan Management: Oversee the acquisition and management of secured and unsecured loans",
          "Compliance: Ensure compliance with all financial regulations and standards",
          "Auditing: Coordinate internal and external audits and implement audit recommendations",
          "Tax Planning: Develop and implement effective tax strategies to optimize the company's tax position",
        ],
        requirements: [
          "Bachelor's degree in Accounting, Finance, or a related field",
        ],
      },
    ],
  },
  {
    designation: "Web Developer",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "3 Years",
    location: "Web Developer",
    vacancy: 1,
    postedOn: new Date("2024-09-13"),
    jobDescription: [
      {
        title: "Web Developer",
        industry: "IT",

        responsibilities: [
          "Website Development: Design, develop, and maintain websites and web applications using modern web technologies.",
          "Front-End Development: Create responsive and user-friendly interfaces using HTML, CSS, JavaScript, and front-end frameworks like React.js, Angular.js, or Vue.js. Ensure cross-browser compatibility and optimize web pages for performance.",
          "Back-End Development: Develop and maintain server-side logic, databases, and APIs. Implement back-end services and data storage solutions to support front-end applications.",
          "Integration and API Development: Integrate third-party APIs and services into web applications. Develop custom APIs to support various features and functionalities.",
          "Collaboration & Project Management: Work closely with designers, project managers or Business Analysts, and other department stakeholders to translate requirements into functional applications. Participate in code reviews, testing, and debugging processes to ensure the highest quality standards.",
          "Maintenance & Support: Monitor, troubleshoot, and resolve issues with existing web applications. Provide ongoing support and enhancements to existing websites and applications.",
          "Security & Compliance: Implement best practices for web security to protect against threats such as XSS, CSRF, SQL injection, and others. Ensure web applications comply with relevant standards and regulations.",
        ],
        requirements: ["BE/Diploma (Electronicas"],
      },
    ],
  },
  {
    designation: "On-premises DevOps Engineer",
    company: "MyInception Tech",
    salaryRange: "4.8 LPA to 6.5 LPA",
    experience: "4+ years",
    location: "T.Nagar,Chennai",
    vacancy: 1,
    postedOn: new Date("2024-09-13"),
    jobDescription: [
      {
        title: "On-premises DevOps Engineer",
        industry: "IT",

        responsibilities: [
          "CI/CD Pipeline Development: Build and maintain continuous integration and continuous deployment (CI/CD) pipelines using tools like Jenkins, GitHub Actions, Bitbucket Pipelines, or similar.",
          "Automation: Develop and manage infrastructure as code (IaC) using tools like Terraform, Ansible, or similar to automate environment provisioning and configuration management.",
          "Monitoring & Optimization: Implement monitoring solutions to ensure infrastructure performance and availability, troubleshoot issues, and optimize resources for cost and performance.",
          "Database Management: Administer on-premises databases, including installation, configuration, performance tuning, backup, recovery, and security. Ensure data integrity and high availability.",
          "Collaboration: Work closely with software development, QA, and operations teams to ensure smooth integration and deployment of applications.",
          "Security: Implement and manage security practices across the infrastructure, including vulnerability assessments, patch management, and compliance with company policies.",
          "Disaster Recovery: Develop and maintain backup and disaster recovery strategies for on-premises environments.",
          "Documentation: Create and maintain detailed documentation of infrastructure configurations, processes, and procedures.",
          "Configuration Management: Read, write, and troubleshoot JSON configuration files used in CI/CD processes, ensuring accuracy and efficiency in pipeline configurations.",
          "Experience with On-Premises Infrastructure",
        ],
        requirements: [
          "Bachelor’s degree in computer science, Engineering, or a related field",
        ],
      },
    ],
  },
  {
    designation: "Full Stack Developer(Senior Engineer)",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "0-2 years",
    location: "T.Nagar, Chennai",
    vacancy: 1,
    postedOn: new Date("2024-09-13"),
    jobDescription: [
      {
        title: "Full Stack Developer(Senior Engineer)",
        industry: "IT",
        responsibilities: [
          "Full Stack Development: Design, develop, and maintain comprehensive applications using a range of technologies and frameworks, ensuring seamless integration within our on-premises infrastructure.",
          "Front-End Development: Develop intuitive and responsive user interfaces using modern front-end technologies such as React, Angular, or Vue.js.",
          "Back-End Development: Build and manage robust server-side applications and APIs using technologies such as Node.js, Python, Java, or .NET.",
          "Database Management: Design, implement, and maintain databases (both SQL and NoSQL) to ensure data integrity, security, and high performance.",
          "Infrastructure Integration: Ensure that applications are efficiently integrated with on-premises infrastructure, including servers, networking components, and storage solutions.",
          "Code Quality: Write clean, maintainable code and conduct thorough code reviews to ensure adherence to best practices and coding standards.",
          "Collaboration: Collaborate with product managers, UX/UI designers, and other developers to gather requirements, design solutions, and deliver high-quality software.",
          "Troubleshooting and Optimization: Diagnose and resolve issues, optimize performance, and implement enhancements to improve functionality and user experience.",
          "Documentation: Create and maintain comprehensive documentation for application code, system architecture, and development processes.",
          "Experience with On-Premises Infrastructure: Hands-on experience with on-premises server management, networking, and deployment.",
        ],
        requirements: [
          "",
        ],
      },
    ],
  },
  {
    designation: "ATE Test Engineer(All Levels)",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "1-5 years",
    location: "T.Nagar, Chennai",
    vacancy: 10,
    postedOn: new Date("2024-09-13"),
    jobDescription: [
      {
        title: "ATE Test Engineer(All Levels)",
        industry: "IT",
        responsibilities: [
          "Experience with at least 1 ATE test platform: Teradyne-uFlex, Advantest-93k, NI-STS.",
  "Experience with development of software such as IGXL, Smart Test, Labview, C# to test Integrated Circuits.",
  "Knowledge and hands-on experience with statistical analysis software packages and techniques, quality and reliability testing. Wafer fabrication fundamentals are a plus.",
  "Solid understanding of electronics engineering fundamentals, digital, analog, mixed-signal, power management, and RF circuit analysis techniques.",
  "Working knowledge of Power Management IC (PMIC) measurement techniques such as line/load regulation, PSRR, transition time, power efficiency, jitter, phase noise, etc.",
  "Good ASIC device level characterization skills. System level knowledge is a plus.",
  "Able to work comfortably with lab equipment like Oscilloscopes, Source Meters, Battery Emulators, Spectrum analyzers, etc.",
  "Able to work independently and with good initiative to overcome technical challenges.",
  "Strong verbal and written communication skills. Able to organize effectively and document work thoroughly while working with local and remote teams.",
  "Strong software skills (VB, Python, Perl, etc.) for writing and debugging test codes.",
  "Familiarity with the New Product Introduction process.",
  "Experience with the development of test hardware utilizing a team approach, reviewing schematics and layouts.",
  "Experience troubleshooting hardware and software to develop test solutions.",
  "Highly motivated, fast learner, team player."
        ],
        requirements: [
          "Bachelor's degree in Relevant field",
        ],
      },
    ],
  },
  {
    designation: "Graduate Apprentice / Engineer Trainee",
    company: "MyInception Tech",
    salaryRange: "Not Disclosed",
    experience: "Fresher",
    location: "T.Nagar, Chennai",
    vacancy: 50,
    postedOn: new Date("2024-09-13"),
    jobDescription: [
      {
        title: "ATE Test Engineer(All Levels)",
        industry: "NonIT",
        responsibilities: [
          "Should have good knowledge in production and quality maintenance.",
          "To inspect the quality of materials in the factory.",
          "Monitoring and maintaining products and services.",
          "Food and transport facilities provided.",
          "Attendance bonus and overtime pay provided.",
          "ESI and PF benefits.",
          "Preferred male candidate."
        
        ],
        requirements: [
          "BE/Diploma",
        ],
      },
    ],
  },
];

export default JobDetails;
