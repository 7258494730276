// import React, { useEffect, useState } from "react";
// import NonITJobDetails from "./Non-IT-JobDetails";
// import "../../CSS/JobPortal/JobPortal.css";
// import { CiLock } from "react-icons/ci";
// import { CiLocationOn } from "react-icons/ci";
// import Button from "@mui/material/Button";
// import ReactModal from "react-modal";
// import LayOut from "../Home/NavigationBar/LayOut";
// import { Link } from "react-router-dom";
// import { IoMdCloseCircle } from "react-icons/io";


// function NonITJobPortal() {
//   const [isModalOpen, setIsModalOpen] = useState(false);


//   const openModal = () => {
//     setIsModalOpen(true);
//     document.body.classList.add("modal-open");
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     document.body.classList.remove("modal-open");
//     setSelectedJob(null);
//   };


//   const [selectedJob, setSelectedJob] = useState(null);

//   const PostTiming = (InitialPostedTime) => {
//     const [elapsedTime, setElapsedTime] = useState("");

//     console.log(elapsedTime);
    

//     useEffect(() => {
//       const calculateElapsedTime = () => {
//         const currentTime = new Date();
//         const timeDifference = currentTime - InitialPostedTime;

//         const seconds = Math.floor(timeDifference / 1000);
//         const minutes = Math.floor(seconds / 60);
//         const hours = Math.floor(minutes / 60);
//         const days = Math.floor(hours / 24);

//         if (days > 0) {
//           setElapsedTime(`${days} ${days === 1 ? "day" : "days"} ago`);
//         } else if (hours > 0) {
//           setElapsedTime(`${hours} ${hours === 1 ? "hour" : "hours"} ago`);
//         } else {
//           setElapsedTime(
//             `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`
//           );
//         }
//       };

//       calculateElapsedTime();

//       const intervalId = setInterval(() => {
//         calculateElapsedTime();
//       }, 60000);

//       return () => clearInterval(intervalId);
//     }, [InitialPostedTime]);

//     return elapsedTime;
//   };

//   const handleJobClick = (job) => {
//     setSelectedJob(job);
//     // setIsModalOpen(true);
//     openModal();
//   };

//   return (
//     <LayOut>
//       <div className="jobPortal">
//         {NonITJobDetails.map((information, index) => (
//           <div key={index} className="searchJob">
//             <div>
//               <div className="designation">{information.designation}</div>
//               <div className="company">{information.company}</div>
//               <div className="work_details">
//                 <span>
//                   {/* <span>₹{information.salaryRange}</span> */}
//                   {/* <span className='separator'>|</span> */}
//                   <span>
//                     {" "}
//                    <span className="lock-location-icon" ><CiLock /></span>  {information.experience}
//                   </span>
//                   <span className="separator1">|</span>
//                   <span>
//                     {" "}
//                     <span className="lock-location-icon" >
//                     <CiLocationOn /> </span>{information.location}
//                   </span>
//                 </span>
//               </div>
//             </div>
//             <div className="vacancy">
//               <span>
//                 <span>Vacancies: {information.vacancy}</span>
//                 <span className="separator">|</span>
//                 <span>{PostTiming(new Date(information.postedOn))}</span>
//                 {/* {console.log(PostTiming(new Date(information.postedOn)))}; */}
                
//               </span>
//             </div>
//             <div className="handleJobClick">
//               <Button
//                 variant="contained"
//                 onClick={() => handleJobClick(information)}
//               >
//                 Apply Here
//               </Button>
//             </div>
//           </div>
//         ))}

//         <ReactModal
//           isOpen={isModalOpen}
//           onRequestClose={closeModal}
//           contentLabel="Course Details Modal"
//           shouldCloseOnOverlayClick={false}
//           style={{
//             content: {
//               transform: "translate(0%, 7%)",
//             },
//           }}
//         >
//           {selectedJob && (
//             <div style={{ maxWidth: "90vw", overflow: "auto" }}>
//               <div className="closePopupButton" onClick={closeModal}><IoMdCloseCircle /></div>
//               <div>
//                 <h2>{selectedJob.designation}</h2>
//                 {selectedJob.jobDescription.map((job, index) => (
//                   <div key={index}>
//                     {job.industry && (
//                       <p>
//                         <h3>Industry:</h3>
//                         {job.industry}
//                       </p>
//                     )}

//                     {job.responsibilities && (
//                       <>
//                         <h3>Responsibilities</h3>
//                         <ul>
//                           {job.responsibilities.map((responsibility, idx) => (
//                             <li key={idx}>{responsibility}</li>
//                           ))}
//                         </ul>
//                       </>
//                     )}

//                     {job.requirements && (
//                       <>
//                         <h3>Requirements</h3>
//                         <ul>
//                           {job.requirements.map((requirement, idx) => (
//                             <li key={idx}>{requirement}</li>
//                           ))}
//                         </ul>
//                       </>
//                     )}

//                     {job.recommendedSkills && (
//                       <>
//                         <h3>Recommended Skills</h3>
//                         <ul>
//                           {job.recommendedSkills.map((skill, idx) => (
//                             <li key={idx}>{skill}</li>
//                           ))}
//                         </ul>
//                       </>
//                     )}
//                   </div>
//                 ))}

//                 <div className="apply-close-btn">
//                   <Link to="/redirect/mail" target="_blank">
//                     <div>
//                       {" "}
//                       <Button variant="contained">Apply</Button>{" "}
//                     </div>
//                   </Link>
//                   <div>
//                     {" "}
//                     <Button variant="contained" onClick={closeModal}>
//                       Close
//                     </Button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}
//         </ReactModal>
//       </div>
//     </LayOut>
//   );
// }

// export default NonITJobPortal;



import React, { useEffect, useState } from "react";
import NonITJobDetails from "./Non-IT-JobDetails";
import "../../CSS/JobPortal/JobPortal.css";
import { CiLock } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import Button from "@mui/material/Button";
import ReactModal from "react-modal";
import LayOut from "../Home/NavigationBar/LayOut";
import { Link } from "react-router-dom";
import { IoMdCloseCircle } from "react-icons/io";

function NonITJobPortal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
    setSelectedJob(null);
  };

  const PostTiming = (InitialPostedTime) => {
    const [elapsedTime, setElapsedTime] = useState("");

    console.log("InitialPostedTime:", InitialPostedTime); // Debugging line

    useEffect(() => {
      const calculateElapsedTime = () => {
        const currentTime = new Date();
        const timeDifference = currentTime - InitialPostedTime;

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        let timeText = "";

        if (days > 0) {
          timeText = `${days} ${days === 1 ? "day" : "days"} ago`;
        } else if (hours > 0) {
          timeText = `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
        } else {
          timeText = `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
        }

        setElapsedTime(timeText);
        console.log("ElapsedTime:", timeText); // Debugging line
      };

      calculateElapsedTime();

      const intervalId = setInterval(() => {
        calculateElapsedTime();
      }, 60000);

      return () => clearInterval(intervalId);
    }, [InitialPostedTime]);

    return elapsedTime;
  };

  const handleJobClick = (job) => {
    setSelectedJob(job);
    openModal();
  };

  return (
    <LayOut>
      <div className="jobPortal">
        {NonITJobDetails.map((information, index) => {
          const postedOnDate = new Date(information.postedOn);
          console.log(`Job ${index + 1} PostedOn:`, postedOnDate); // Debugging line
          return (
            <div key={index} className="searchJob">
              <div>
                <div className="designation">{information.designation}</div>
                <div className="company">{information.company}</div>
                <div className="work_details">
                  <span>
                    <span className="lock-location-icon"><CiLock /></span> {information.experience}
                  </span>
                  <span className="separator1">|</span>
                  <span>
                    <span className="lock-location-icon"><CiLocationOn /></span>{information.location}
                  </span>
                </div>
              </div>
              <div className="vacancy">
                <span>
                  <span>Vacancies: {information.vacancy}</span>
                  <span className="separator">|</span>
                  <span>{PostTiming(postedOnDate)}</span>
                </span>
              </div>
              <div className="handleJobClick">
                <Button
                  variant="contained"
                  onClick={() => handleJobClick(information)}
                >
                  Apply Here
                </Button>
              </div>
            </div>
          );
        })}

        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Course Details Modal"
          shouldCloseOnOverlayClick={false}
          style={{
            content: {
              transform: "translate(0%, 7%)",
            },
          }}
        >
          {selectedJob && (
            <div style={{ maxWidth: "90vw", overflow: "auto" }}>
              <div className="closePopupButton" onClick={closeModal}><IoMdCloseCircle /></div>
              <div>
                <h2>{selectedJob.designation}</h2>
                {selectedJob.jobDescription.map((job, index) => (
                  <div key={index}>
                    {job.industry && (
                      <p>
                        <h3>Industry:</h3>
                        {job.industry}
                      </p>
                    )}

                    {job.responsibilities && (
                      <>
                        <h3>Responsibilities</h3>
                        <ul>
                          {job.responsibilities.map((responsibility, idx) => (
                            <li key={idx}>{responsibility}</li>
                          ))}
                        </ul>
                      </>
                    )}

                    {job.requirements && (
                      <>
                        <h3>Requirements</h3>
                        <ul>
                          {job.requirements.map((requirement, idx) => (
                            <li key={idx}>{requirement}</li>
                          ))}
                        </ul>
                      </>
                    )}

                    {job.recommendedSkills && (
                      <>
                        <h3>Recommended Skills</h3>
                        <ul>
                          {job.recommendedSkills.map((skill, idx) => (
                            <li key={idx}>{skill}</li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                ))}

                <div className="apply-close-btn">
                  <Link to="/redirect/mail" target="_blank">
                    <div>
                      {" "}
                      <Button variant="contained">Apply</Button>{" "}
                    </div>
                  </Link>
                  <div>
                    {" "}
                    <Button variant="contained" onClick={closeModal}>
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ReactModal>
      </div>
    </LayOut>
  );
}

export default NonITJobPortal;

