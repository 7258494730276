// import React, { useState } from "react";

// import { RiUserSettingsFill } from "react-icons/ri";
// import { GiGlobe } from "react-icons/gi";
// import { FaPersonBooth } from "react-icons/fa6";
// import { FaPersonChalkboard } from "react-icons/fa6";
// import { MdComputer } from "react-icons/md";
// import { FaComputer } from "react-icons/fa6";

// import Software from "../Services/Software";
// import ModalWindow from "../../Reusuable_components/ModalWindow";

// const Serv = () => {
//   const [modal, setModal] = useState(false);
 
//   const toggleModal = () => {
//     setModal(!modal);
//   };
 
//   const trimContent = (content, maxLength) => {
//     return content.length > maxLength
//       ? content.slice(0, maxLength) + "..."
//       : content;
//   };

//   const newObj = [
//     {
//       icon: <FaPersonBooth />,
//       heading: "Placement Support",
//       content:
//         "MyInception Tech is committed to your success beyond training. Our placement support services are designed to bridge the gap between your training journey and your dream job. We understand that the transition from training to the workplace can be a critical phase, and we're here to ensure a smooth and successful transition...",
//       popup: [
//         {
//           heading: "Placement Support",
//           paragraph:
//             "MyInception Tech is committed to your success beyond training. Our placement support services are designed to bridge the gap between your training journey and your dream job. We understand that the transition from training to the workplace can be a critical phase, and we're here to ensure a smooth and successful transition.",
//         },
//         {
//           title: "Industry Connections:",
//           paragraph:
//             "We have cultivated strong connections with leading companies in various industries. These connections facilitate direct interactions between our graduates and potential employers, increasing your chances of securing your dream job.",
//         },
//         {
//           title: "Resume Building:",
//           paragraph:
//             "Our expert team assists you in creating a compelling resume that highlights your skills and accomplishments. We understand what employers are looking for, and we help you craft a resume that makes a strong impression on recruiters.",
//         },
//         {
//           title: "Mock Interviews:",
//           paragraph:
//             "To boost your confidence and equip you with effective interview strategies, we conduct mock interviews. These simulations prepare you for real job interviews, ensuring you're well-prepared to showcase your abilities.",
//         },
//         {
//           title: "Workshops:",
//           paragraph:
//             "Participate in career-focused workshops that cover essential topics such as networking, professional etiquette, and effective job searching strategies. These workshops provide you with valuable insights into the job market.",
//         },
//         {
//           title: "Internship Opportunities:",
//           paragraph:
//             "We connect you with internship opportunities that offer practical experience and increase your marketability to potential employers. Internships are a valuable stepping stone to your desired career.",
//         },
//         {
//           title: "Extended Support:",
//           paragraph:
//             "Our commitment to your placement doesn't end with your graduation. We offer ongoing support and guidance as you navigate your career path. Whether you need advice, assistance, or mentorship, we're here to help.",
//         },
//         {
//           paragraph:
//             "At MyInception Tech, we believe that your success is our success. Our placement support services are designed to empower you to secure the job you've worked hard for. Join us, and let's embark on this journey together.",
//         },
//       ],
//     },
//     {
//       icon: <FaPersonChalkboard />,
//       heading: "Training Programs",
//       content:
//         "MyInception Tech's meticulously crafted training programs are tailored to empower individuals like you with the knowledge and skills essential to thrive in the ever-evolving technology landscape. Whether you're aspiring to become a proficient IT professional or aiming to enhance your existing expertise, our training programs serve as your pathway to excellence, guiding you toward mastery...",
//       popup: [
//         {
//           heading: "Training Programs",
//           paragraph:
//             "MyInception Tech's meticulously crafted training programs are tailored to empower individuals like you with the knowledge and skills essential to thrive in the ever-evolving technology landscape. Whether you're aspiring to become a proficient IT professional or aiming to enhance your existing expertise, our training programs serve as your pathway to excellence, guiding you toward mastery.",
//         },
//         {
//           title: "Comprehensive Curriculum",
//           paragraph:
//             "Our commitment to your success begins with the curriculum we offer. We provide comprehensive courses that delve deep into the latest industry trends and cutting-edge technologies. Each program is thoughtfully crafted to ensure that you gain a holistic understanding of the subject matter. You'll explore not only the fundamentals but also advanced concepts, preparing you to tackle real-world challenges in the tech landscape.",
//           },
//         {
//           title: "Expert Instructors",
//           paragraph:
//             "Learning from experienced industry experts is at the heart of our training philosophy. Our instructors are not just educators; they are passionate professionals with a wealth of knowledge and a strong desire to impart their expertise. Their real-world experience and dedication to their craft ensure that you receive not only theoretical insights but also practical insights that can make a difference in your career.",
//         },
//         {
//           paragraph:
//             "Our instructors are committed to your growth, providing guidance, mentorship, and personalized attention to help you succeed in your learning journey.",
//         },
//         {
//           title: "Your Path to Expertise in Tech and Core Industries",
//           paragraph:
//             "In both the tech and core industries, hands-on experience is invaluable. We recognize the importance of practical knowledge, and that's why our training programs emphasize hands-on training and real-world projects. You won't just learn theories; you'll apply your knowledge to practical scenarios, solving problems, and building solutions.",
//         },
//         {
//           paragraph:
//             "Through hands-on exercises and projects, you'll develop the confidence and skills necessary to excel in your chosen field. Whether it's coding, data analysis, or any other domain, our training ensures you are well-prepared to tackle the challenges you'll encounter in your career.",
//         },
//         {
//           title: "Job Search Assistance",
//           paragraph:
//             "Beyond training, we offer comprehensive job search assistance to initiate your career journey. Our training programs come with a job guarantee and 100 percent placement aid. We take pride in our track record of helping our graduates secure rewarding positions in top-tier companies.",
//          }, {
//           title: "Expert Instructors",
//           paragraph:
//             "Learning from experienced industry experts is at the heart of our training philosophy. Our instructors are not just educators; they are passionate professionals with a wealth of knowledge and a strong desire to impart their expertise. Their real-world experience and dedication to their craft ensure that you receive not only theoretical insights but also practical insights that can make a difference in your career.",
//         },
//         {
//           paragraph:
//             "Our instructors are committed to your growth, providing guidance, mentorship, and personalized attention to help you succeed in your learning journey.",
//         },
//       ],
//     },
//     {
//       icon: <RiUserSettingsFill />,
//       heading: "Staffing Solutions",
//       content:
//         "MyInception Tech recruitment services are dedicated to bridging the gap between talented professionals and rewarding career opportunities. When you choose to partner with us for your recruitment needs, you unlock a range of benefits that are designed to enhance your organization's success....",
//       popup: [
//         {
//           heading: "Staffing Solutions",
//           paragraph:
//             "MyInception Tech recruitment services are dedicated to bridging the gap between talented professionals and rewarding career opportunities. When you choose to partner with us for your recruitment needs, you unlock a range of benefits that are designed to enhance your organization's success.",
//         },
//         {
//           title: "Access to Top Talent",
//           paragraph:
//             "Our extensive network is a treasure trove of top-tier talent. We understand that finding the right candidate with the perfect blend of skills and cultural fit is crucial to your organization's growth. Our recruitment experts are well-connected in various industries, allowing us to swiftly identify and engage with qualified candidates who are eager to contribute to your success.",
//         },{
//           title: "Efficient Hiring",
//           paragraph:
//             "Recruitment can be a time-consuming process, often requiring a substantial investment in time and resources. MyInception Tech is here to streamline your recruitment journey. With our assistance, you can expedite the hiring process, reduce administrative burdens, and focus on what matters most—selecting the right professionals who will drive your organization forward.",
//         },
//         {
//           title: "Customized Solutions",
//           paragraph:
//             "We recognize that each organization has unique staffing needs. Our commitment is to provide customized solutions tailored to match your specific requirements. Whether you need to fill permanent positions, contract roles, or temporary assignments, we work closely with you to understand your organization's dynamics, culture, and objectives. This enables us to deliver staffing solutions that align seamlessly with your goals.",
//         },
//         {
//           paragraph:
//             "Our recruitment services are not just about filling vacancies; they are about finding the perfect match that will contribute to your organization's growth, innovation, and success. We take pride in our ability to connect you with exceptional professionals who will make a meaningful impact on your team and business.",
//         },
//         {
//           paragraph:
//             "If you're ready to access top talent efficiently and benefit from customized staffing solutions, MyInception Tech is your trusted partner. Contact us today to explore how our recruitment services can elevate your organization's success. Your next great hire is just a step away.",
//         },
//       ],
//     },
//     {
//       icon: <FaComputer />,
//       heading: "Call Center/BPO Services",
//       content:
//         "MyInception Tech excels at providing exceptional call center and BPO services tailored to diverse industry needs. Our dedicated teams handle customer interactions with finesse and expertise, ensuring high-quality service, customer satisfaction, and business efficiency....",
//       popup: [
//         {
//           heading: "Call Center/BPO Services",
//           paragraph:
//             "MyInception Tech excels at providing exceptional call center and BPO services tailored to diverse industry needs. Our dedicated teams handle customer interactions with finesse and expertise, ensuring high-quality service, customer satisfaction, and business efficiency.",
//         },
//         {
//           title: "Industries We Serve:",
//           subTitle: "Logistics",
//           paragraph:
//             " In the logistics sector, timely communication and precise information are crucial. Our call center services for logistics companies include order tracking, delivery inquiries, and issue resolution. We facilitate smooth communication between your business and clients, ensuring that shipments are efficiently managed and customer queries are promptly addressed.",
//         },
//         {
//           subTitle: "Banking",
//           paragraph:
//             "Trust and accuracy are paramount in the banking industry. We offer comprehensive support for banking sectors, including account inquiries, transaction clarifications, and dispute resolution. Our team ensures that your clients receive accurate and detailed information, fostering trust and confidence in your banking services.",
//         },
//         {
//           title: "Services We Provide:",
//           subTitle: "Chat Support:",
//           paragraph:
//             "Our live chat support offers instant responses, product information, and query resolution. With real-time interactions, we enhance customer engagement and provide quick solutions to their inquiries.",
//         },
//         {
//           subTitle: "Call Support:",
//           paragraph:
//             " Through personalized phone interactions, we strengthen customer relationships. Our call support team offers dedicated assistance, ensuring that clients receive the attention they deserve and their issues are resolved effectively.",
//         },
//         {
//           title: "Why Choose Our Call Center/BPO Services?",
//           subTitle: "Versatility:",
//           paragraph:
//             "Our services are adaptable and suitable for a wide range of industries, ensuring that we can cater to your unique business requirements.",
//         },
//         {
//           subTitle: "Professionalism:",
//           paragraph:
//             "Our trained agents exhibit professionalism and courtesy in every customer interaction, reflecting positively on your brand.",
//         },
//         {
//           title:
//             "Upgrade Your Customer Service Today with Our Expert Call Center/BPO Solutions!",
//         },
//       ],
//     },
//     {
//       icon: <MdComputer />,
//       heading: "Software Development",
//       content:
//         "MyInception Tech leads the way in innovative Software development, offering tailored solutions in Machine learning, Mobile applications, and Web development....",
//       popup: [
//         {
//           heading: "Software Development",
//           paragraph:
//             "MyInception Tech leads the way in innovative Software development, offering tailored solutions in Machine learning, Mobile applications, and Web development.",
//         },
//         {
//           title: "Machine Learning:",
//           paragraph:
//             "Leverage intelligent algorithms with our machine learning services. We create applications that adapt, enabling data-driven decisions and enhanced user experiences. Our expert team delves deep into the realms of artificial intelligence, transforming raw data into meaningful insights that drive your business forward.",
//         },
//         {
//           title: "Mobile Applications:",
//           paragraph:
//             "From business optimization to engaging user interfaces, our team crafts dynamic Android and iOS applications tailored to your needs, ensuring seamless user experiences. We not only focus on functionality but also infuse creativity, ensuring your mobile app captures attention and retains users.",
//         },
//         {
//           title: "Web Solutions:",
//           paragraph:
//             "Our web development expertise encompasses responsive websites and complex web applications. We guarantee fast, secure, and visually appealing online experiences. With user-centric design principles and cutting-edge technology, we create web solutions that leave a lasting impression, driving your online presence to new heights.",
//         },
//         {
//           title: "Why Choose MyInception Tech:",
//           subTitle: "Expert Developers:",
//           paragraph:
//             "Our skilled team ensures your project is in the hands of experienced professionals.",
//         },{
//           title: "Why Choose MyInception Tech:",
//           subTitle: "Expert Developers:",
//           paragraph:
//             "Our skilled team ensures your project is in the hands of experienced professionals.",
//         },
//         {
//           subTitle: "Tailored Solutions:",
//           paragraph:
//             "We understand each project is unique; our approach is customized to match your vision.",
//         },
//         {
//           subTitle: "User-Centric Design:",
//           paragraph:
//             " Prioritizing intuitive interfaces enhances user satisfaction and engagement.",
//         },
//         {
//           subTitle: "Cutting-Edge Technology:",
//           paragraph:
//             "Stay ahead with the latest tech, ensuring modern, scalable solutions.",
//         },
//       ],
//     },

//     {
//       icon: <GiGlobe />,
//       heading: "Data Annotation",
//       content:
//         "MyInception Tech specializes in providing precise data annotations vital for machine learning algorithms. Our expert teams ensure accuracy and reliability, whether it's annotating images, videos, or other forms of data. Elevate your AI models' learning capabilities with our top-notch annotations....",
//       popup: [
//         {
//           heading: "Data Annotation",
//           paragraph:
//             "MyInception Tech specializes in providing precise data annotations vital for machine learning algorithms. Our expert teams ensure accuracy and reliability, whether it's annotating images, videos, or other forms of data. Elevate your AI models' learning capabilities with our top-notch annotations.",
//         },
//         {
//           title: "Images:",
//           paragraph:
//             "In the realm of computer vision, our annotation services bring clarity to images, enabling AI systems to recognize and process visual information. From object recognition to facial analysis, our annotations train AI algorithms for diverse applications like autonomous vehicles and facial recognition systems.",
//         },
//         {
//           title: "Videos:",
//           paragraph:
//             "For industries reliant on video analysis, our experts annotate video data, enhancing your machine learning models' ability to comprehend moving images. From surveillance footage analysis for security AI to improving content recommendation algorithms for entertainment, our annotations drive intelligent video analysis in AI systems.",
//         },
//         {
//           title: "Text Data:",
//           paragraph:
//             "In the domain of natural language processing, we annotate text data, making sense of textual information. From sentiment analysis to language translation, our annotations empower AI systems to understand and interpret human language, contributing to advancements in chatbots, language translation apps, and sentiment analysis tools.",
//         },
//         {
//           title: "Industries We Serve:",
//           paragraph:
//             "Our data annotation services cater to a wide array of industries, including healthcare (supporting AI-powered diagnostics and research), automotive (for autonomous vehicle technologies),.",
//         },
//         {
//           title:
//             "Supercharge Your AI with Expert Data Annotations. Boost Accuracy and Efficiency!",
//         },
//       ],
//     },
//   ];
//   return (
//     <div className="container">
//       {newObj.map((item, index) => (
//         <div key={index} className="contain">
          
//             <p className="icon">{item.icon} </p>
        
//           <h4 className="heading">{item.heading}</h4>
//           <p className="services_description">
//             {trimContent(item.content, 120)}
//           </p>
//           <br />
//           {item.popup && (
//             <ModalWindow
//               triggerText={"Read more..."}
//               customContent={item.popup}
//             />
//           )}
//         </div>
//       ))}

//       {modal && (
//         <div className="modal">
//           <div onClick={toggleModal} className="overlay">
//             <div className="modal-contant">
//               {Software.map((item, index) => (
//                 <div key={index}>
//                   <h1>{item.heading}</h1>
//                   <p>{item.description}</p>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };


// export default Serv;



import React, { useState } from "react";

import { RiUserSettingsFill } from "react-icons/ri";
import { GiGlobe } from "react-icons/gi";
import { FaPersonBooth } from "react-icons/fa6";
import { FaPersonChalkboard } from "react-icons/fa6";
import { MdComputer } from "react-icons/md";
import { FaComputer } from "react-icons/fa6";

import Software from "../Services/Software";
import ModalWindow from "../../Reusuable_components/ModalWindow";

const Serv = () => {
  const [modal, setModal] = useState(false);
 
  const toggleModal = () => {
    setModal(!modal);
  };
 
  const trimContent = (content, maxLength) => {
    return content.length > maxLength
      ? content.slice(0, maxLength) + "..."
      : content;
  };

  const newObj = [
    {
      icon: <FaPersonBooth />,
      heading: "Placement Support",
      content:
        "MyInception Tech is committed to your success beyond training. Our placement support services are designed to bridge the gap between your training journey and your dream job. We understand that the transition from training to the workplace can be a critical phase, and we're here to ensure a smooth and successful transition...",
      popup: [
        {
          heading: "Placement Support",
          paragraph:
            "MyInception Tech is committed to your success beyond training. Our placement support services are designed to bridge the gap between your training journey and your dream job. We understand that the transition from training to the workplace can be a critical phase, and we're here to ensure a smooth and successful transition.",
        },
        {
          title: "Industry Connections:",
          paragraph:
            "We have cultivated strong connections with leading companies in various industries. These connections facilitate direct interactions between our graduates and potential employers, increasing your chances of securing your dream job.",
        },
        {
          title: "Resume Building:",
          paragraph:
            "Our expert team assists you in creating a compelling resume that highlights your skills and accomplishments. We understand what employers are looking for, and we help you craft a resume that makes a strong impression on recruiters.",
        },
        {
          title: "Mock Interviews:",
          paragraph:
            "To boost your confidence and equip you with effective interview strategies, we conduct mock interviews. These simulations prepare you for real job interviews, ensuring you're well-prepared to showcase your abilities.",
        },
        {
          title: "Workshops:",
          paragraph:
            "Participate in career-focused workshops that cover essential topics such as networking, professional etiquette, and effective job searching strategies. These workshops provide you with valuable insights into the job market.",
        },
        {
          title: "Internship Opportunities:",
          paragraph:
            "We connect you with internship opportunities that offer practical experience and increase your marketability to potential employers. Internships are a valuable stepping stone to your desired career.",
        },
        {
          title: "Extended Support:",
          paragraph:
            "Our commitment to your placement doesn't end with your graduation. We offer ongoing support and guidance as you navigate your career path. Whether you need advice, assistance, or mentorship, we're here to help.",
        },
        {
          paragraph:
            "At MyInception Tech, we believe that your success is our success. Our placement support services are designed to empower you to secure the job you've worked hard for. Join us, and let's embark on this journey together.",
        },
      ],
    },
    {
      icon: <FaPersonChalkboard />,
      heading: "Training Programs",
      content:
        "MyInception Tech's meticulously crafted training programs are tailored to empower individuals like you with the knowledge and skills essential to thrive in the ever-evolving technology landscape. Whether you're aspiring to become a proficient IT professional or aiming to enhance your existing expertise, our training programs serve as your pathway to excellence, guiding you toward mastery...",
      popup: [
        {
          heading: "Training Programs",
          paragraph:
            "MyInception Tech's meticulously crafted training programs are tailored to empower individuals like you with the knowledge and skills essential to thrive in the ever-evolving technology landscape. Whether you're aspiring to become a proficient IT professional or aiming to enhance your existing expertise, our training programs serve as your pathway to excellence, guiding you toward mastery.",
        },
        {
          title: "Comprehensive Curriculum",
          paragraph:
            "Our commitment to your success begins with the curriculum we offer. We provide comprehensive courses that delve deep into the latest industry trends and cutting-edge technologies. Each program is thoughtfully crafted to ensure that you gain a holistic understanding of the subject matter. You'll explore not only the fundamentals but also advanced concepts, preparing you to tackle real-world challenges in the tech landscape.",
          },
        {
          title: "Expert Instructors",
          paragraph:
            "Learning from experienced industry experts is at the heart of our training philosophy. Our instructors are not just educators; they are passionate professionals with a wealth of knowledge and a strong desire to impart their expertise. Their real-world experience and dedication to their craft ensure that you receive not only theoretical insights but also practical insights that can make a difference in your career.",
        },
        {
          paragraph:
            "Our instructors are committed to your growth, providing guidance, mentorship, and personalized attention to help you succeed in your learning journey.",
        },
        {
          title: "Your Path to Expertise in Tech and Core Industries",
          paragraph:
            "In both the tech and core industries, hands-on experience is invaluable. We recognize the importance of practical knowledge, and that's why our training programs emphasize hands-on training and real-world projects. You won't just learn theories; you'll apply your knowledge to practical scenarios, solving problems, and building solutions.",
        },
        {
          paragraph:
            "Through hands-on exercises and projects, you'll develop the confidence and skills necessary to excel in your chosen field. Whether it's coding, data analysis, or any other domain, our training ensures you are well-prepared to tackle the challenges you'll encounter in your career.",
        },
        {
          title: "Job Search Assistance",
          paragraph:
            "Beyond training, we offer comprehensive job search assistance to initiate your career journey. Our training programs come with a job guarantee and 100 percent placement aid. We take pride in our track record of helping our graduates secure rewarding positions in top-tier companies.",
         }, {
          title: "Expert Instructors",
          paragraph:
            "Learning from experienced industry experts is at the heart of our training philosophy. Our instructors are not just educators; they are passionate professionals with a wealth of knowledge and a strong desire to impart their expertise. Their real-world experience and dedication to their craft ensure that you receive not only theoretical insights but also practical insights that can make a difference in your career.",
        },
        {
          paragraph:
            "Our instructors are committed to your growth, providing guidance, mentorship, and personalized attention to help you succeed in your learning journey.",
        },
      ],
    },
    {
      icon: <RiUserSettingsFill />,
      heading: "Staffing Solutions",
      content:
        "MyInception Tech recruitment services are dedicated to bridging the gap between talented professionals and rewarding career opportunities. When you choose to partner with us for your recruitment needs, you unlock a range of benefits that are designed to enhance your organization's success....",
      popup: [
        {
          heading: "Staffing Solutions",
          paragraph:
            "MyInception Tech recruitment services are dedicated to bridging the gap between talented professionals and rewarding career opportunities. When you choose to partner with us for your recruitment needs, you unlock a range of benefits that are designed to enhance your organization's success.",
        },
        {
          title: "Access to Top Talent",
          paragraph:
            "Our extensive network is a treasure trove of top-tier talent. We understand that finding the right candidate with the perfect blend of skills and cultural fit is crucial to your organization's growth. Our recruitment experts are well-connected in various industries, allowing us to swiftly identify and engage with qualified candidates who are eager to contribute to your success.",
        },{
          title: "Efficient Hiring",
          paragraph:
            "Recruitment can be a time-consuming process, often requiring a substantial investment in time and resources. MyInception Tech is here to streamline your recruitment journey. With our assistance, you can expedite the hiring process, reduce administrative burdens, and focus on what matters most—selecting the right professionals who will drive your organization forward.",
        },
        {
          title: "Customized Solutions",
          paragraph:
            "We recognize that each organization has unique staffing needs. Our commitment is to provide customized solutions tailored to match your specific requirements. Whether you need to fill permanent positions, contract roles, or temporary assignments, we work closely with you to understand your organization's dynamics, culture, and objectives. This enables us to deliver staffing solutions that align seamlessly with your goals.",
        },
        {
          paragraph:
            "Our recruitment services are not just about filling vacancies; they are about finding the perfect match that will contribute to your organization's growth, innovation, and success. We take pride in our ability to connect you with exceptional professionals who will make a meaningful impact on your team and business.",
        },
        {
          paragraph:
            "If you're ready to access top talent efficiently and benefit from customized staffing solutions, MyInception Tech is your trusted partner. Contact us today to explore how our recruitment services can elevate your organization's success. Your next great hire is just a step away.",
        },
      ],
    },
    {
      icon: <FaComputer />,
      heading: "Call Center/BPO Services",
      content:
        "MyInception Tech excels at providing exceptional call center and BPO services tailored to diverse industry needs. Our dedicated teams handle customer interactions with finesse and expertise, ensuring high-quality service, customer satisfaction, and business efficiency....",
      popup: [
        {
          heading: "Call Center/BPO Services",
          paragraph:
            "MyInception Tech excels at providing exceptional call center and BPO services tailored to diverse industry needs. Our dedicated teams handle customer interactions with finesse and expertise, ensuring high-quality service, customer satisfaction, and business efficiency.",
        },
        {
          title: "Industries We Serve:",
          subTitle: "Logistics",
          paragraph:
            " In the logistics sector, timely communication and precise information are crucial. Our call center services for logistics companies include order tracking, delivery inquiries, and issue resolution. We facilitate smooth communication between your business and clients, ensuring that shipments are efficiently managed and customer queries are promptly addressed.",
        },
        {
          subTitle: "Banking",
          paragraph:
            "Trust and accuracy are paramount in the banking industry. We offer comprehensive support for banking sectors, including account inquiries, transaction clarifications, and dispute resolution. Our team ensures that your clients receive accurate and detailed information, fostering trust and confidence in your banking services.",
        },
        {
          title: "Services We Provide:",
          subTitle: "Chat Support:",
          paragraph:
            "Our live chat support offers instant responses, product information, and query resolution. With real-time interactions, we enhance customer engagement and provide quick solutions to their inquiries.",
        },
        {
          subTitle: "Call Support:",
          paragraph:
            " Through personalized phone interactions, we strengthen customer relationships. Our call support team offers dedicated assistance, ensuring that clients receive the attention they deserve and their issues are resolved effectively.",
        },
        {
          title: "Why Choose Our Call Center/BPO Services?",
          subTitle: "Versatility:",
          paragraph:
            "Our services are adaptable and suitable for a wide range of industries, ensuring that we can cater to your unique business requirements.",
        },
        {
          subTitle: "Professionalism:",
          paragraph:
            "Our trained agents exhibit professionalism and courtesy in every customer interaction, reflecting positively on your brand.",
        },
        {
          title:
            "Upgrade Your Customer Service Today with Our Expert Call Center/BPO Solutions!",
        },
      ],
    },
    {
      icon: <MdComputer />,
      heading: "Software Development",
      content:
        "MyInception Tech leads the way in innovative Software development, offering tailored solutions in Machine learning, Mobile applications, and Web development....",
      popup: [
        {
          heading: "Software Development",
          paragraph:
            "MyInception Tech leads the way in innovative Software development, offering tailored solutions in Machine learning, Mobile applications, and Web development.",
        },
        {
          title: "Machine Learning:",
          paragraph:
            "Leverage intelligent algorithms with our machine learning services. We create applications that adapt, enabling data-driven decisions and enhanced user experiences. Our expert team delves deep into the realms of artificial intelligence, transforming raw data into meaningful insights that drive your business forward.",
        },
        {
          title: "Mobile Applications:",
          paragraph:
            "From business optimization to engaging user interfaces, our team crafts dynamic Android and iOS applications tailored to your needs, ensuring seamless user experiences. We not only focus on functionality but also infuse creativity, ensuring your mobile app captures attention and retains users.",
        },
        {
          title: "Web Solutions:",
          paragraph:
            "Our web development expertise encompasses responsive websites and complex web applications. We guarantee fast, secure, and visually appealing online experiences. With user-centric design principles and cutting-edge technology, we create web solutions that leave a lasting impression, driving your online presence to new heights.",
        },
        {
          title: "Why Choose MyInception Tech:",
          subTitle: "Expert Developers:",
          paragraph:
            "Our skilled team ensures your project is in the hands of experienced professionals.",
        },{
          title: "Why Choose MyInception Tech:",
          subTitle: "Expert Developers:",
          paragraph:
            "Our skilled team ensures your project is in the hands of experienced professionals.",
        },
        {
          subTitle: "Tailored Solutions:",
          paragraph:
            "We understand each project is unique; our approach is customized to match your vision.",
        },
        {
          subTitle: "User-Centric Design:",
          paragraph:
            " Prioritizing intuitive interfaces enhances user satisfaction and engagement.",
        },
        {
          subTitle: "Cutting-Edge Technology:",
          paragraph:
            "Stay ahead with the latest tech, ensuring modern, scalable solutions.",
        },
      ],
    },

    {
      icon: <GiGlobe />,
      heading: "Data Annotation",
      content:
        "MyInception Tech specializes in providing precise data annotations vital for machine learning algorithms. Our expert teams ensure accuracy and reliability, whether it's annotating images, videos, or other forms of data. Elevate your AI models' learning capabilities with our top-notch annotations....",
      popup: [
        {
          heading: "Data Annotation",
          paragraph:
            "MyInception Tech specializes in providing precise data annotations vital for machine learning algorithms. Our expert teams ensure accuracy and reliability, whether it's annotating images, videos, or other forms of data. Elevate your AI models' learning capabilities with our top-notch annotations.",
        },
        {
          title: "Images:",
          paragraph:
            "In the realm of computer vision, our annotation services bring clarity to images, enabling AI systems to recognize and process visual information. From object recognition to facial analysis, our annotations train AI algorithms for diverse applications like autonomous vehicles and facial recognition systems.",
        },
        {
          title: "Videos:",
          paragraph:
            "For industries reliant on video analysis, our experts annotate video data, enhancing your machine learning models' ability to comprehend moving images. From surveillance footage analysis for security AI to improving content recommendation algorithms for entertainment, our annotations drive intelligent video analysis in AI systems.",
        },
        {
          title: "Text Data:",
          paragraph:
            "In the domain of natural language processing, we annotate text data, making sense of textual information. From sentiment analysis to language translation, our annotations empower AI systems to understand and interpret human language, contributing to advancements in chatbots, language translation apps, and sentiment analysis tools.",
        },
        {
          title: "Industries We Serve:",
          paragraph:
            "Our data annotation services cater to a wide array of industries, including healthcare (supporting AI-powered diagnostics and research), automotive (for autonomous vehicle technologies),.",
        },
        {
          title:
            "Supercharge Your AI with Expert Data Annotations. Boost Accuracy and Efficiency!",
        },
      ],
    },
  ];
  return (
    <div className="container">
      {newObj.map((item, index) => (
        <div key={index} className="contain">
          
            <p className="icon">{item.icon} </p>
        
          <h4 className="heading">{item.heading}</h4>
          <p className="services_description">
            {trimContent(item.content, 120)}
          </p>
          <br />
          {item.popup && (
            <ModalWindow
              triggerText={"Read more..."}
              customContent={item.popup}
            />
          )}
        </div>
      ))}

      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay">
            <div className="modal-contant">
              {Software.map((item, index) => (
                <div key={index}>
                  <h1>{item.heading}</h1>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


export default Serv;