import React, { useEffect, useState } from "react";
import JobDetails from "./JobDetails";
import "../../CSS/JobPortal/JobPortal.css";
import { CiLock, CiLocationOn } from "react-icons/ci";
import Button from "@mui/material/Button";
import ReactModal from "react-modal";
import LayOut from "../Home/NavigationBar/LayOut";
import { Link } from "react-router-dom";
import { IoMdCloseCircle } from "react-icons/io";

function JobportalNonIT() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
    setSelectedJob(null);
  };

  const PostTiming = (InitialPostedTime) => {
    const [elapsedTime, setElapsedTime] = useState("");

    useEffect(() => {
      const calculateElapsedTime = () => {
        const currentTime = new Date();
        const timeDifference = currentTime - InitialPostedTime;

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
          setElapsedTime(`${days} ${days === 1 ? "day" : "days"} ago`);
        } else if (hours > 0) {
          setElapsedTime(`${hours} ${hours === 1 ? "hour" : "hours"} ago`);
        } else {
          setElapsedTime(`${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`);
        }
      };

      calculateElapsedTime();

      const intervalId = setInterval(() => {
        calculateElapsedTime();
      }, 60000);

      return () => clearInterval(intervalId);
    }, [InitialPostedTime]);

    return elapsedTime;
  };

  const handleJobClick = (job) => {
    setSelectedJob(job);
    openModal();
  };

  // Filter job details based on the industry, in this case "IT"
  const filteredJobDetails = JobDetails.filter((job) =>
    job.jobDescription.some((desc) => desc.industry === "NonIT")
  );

  return (
    <LayOut>
      <div className="jobPortal">
        {filteredJobDetails.map((information, index) => (
          <div key={index} className="searchJob">
            <div>
              <div className="designation">{information.designation}</div>
              <div className="company">{information.company}</div>
              <div className="work_details">
                <span>
                  <span>
                    <span className="lock-location-icon"><CiLock /></span> {information.experience}
                  </span>
                  <span className="separator1">|</span>
                  <span>
                    <span className="lock-location-icon"><CiLocationOn /></span> {information.location}
                  </span>
                </span>
              </div>
            </div>
            <div className="vacancy">
              <span>
                <span>Vacancies: {information.vacancy}</span>
                <span className="separator">|</span>
                <span>{PostTiming(new Date(information.postedOn))}</span>
              </span>
            </div>
            <div className="handleJobClick">
              <Button
                variant="contained"
                onClick={() => handleJobClick(information)}
              >
                Apply Here
              </Button>
            </div>
          </div>
        ))}

        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Course Details Modal"
          shouldCloseOnOverlayClick={false}
          style={{
            content: {
              transform: "translate(0%, 7%)",
            },
          }}
        >
          {selectedJob && (
            <div style={{ maxWidth: "90vw", overflow: "auto" }}>
              <div className="closePopupButton" onClick={closeModal}>
                <IoMdCloseCircle />
              </div>
              <div>
                <h2>{selectedJob.designation}</h2>
                {selectedJob.jobDescription.map((job, index) => (
                  <div key={index}>
                    {job.industry && (
                      <p>
                        <h3>Industry:</h3>
                        {job.industry}
                      </p>
                    )}

                    {job.responsibilities && (
                      <>
                        <h3>Responsibilities</h3>
                        <ul>
                          {job.responsibilities.map((responsibility, idx) => (
                            <li key={idx}>{responsibility}</li>
                          ))}
                        </ul>
                      </>
                    )}

                    {job.requirements && (
                      <>
                        <h3>Requirements</h3>
                        <ul>
                          {job.requirements.map((requirement, idx) => (
                            <li key={idx}>{requirement}</li>
                          ))}
                        </ul>
                      </>
                    )}

                    {job.recommendedSkills && (
                      <>
                        <h3>Recommended Skills</h3>
                        <ul>
                          {job.recommendedSkills.map((skill, idx) => (
                            <li key={idx}>{skill}</li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                ))}

                <div className="apply-close-btn">
                  <Link to="/redirect/mail" target="_blank">
                    <div>
                      <Button variant="contained">Apply</Button>
                    </div>
                  </Link>
                  <div>
                    <Button variant="contained" onClick={closeModal}>
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ReactModal>
      </div>
    </LayOut>
  );
}

export default JobportalNonIT;